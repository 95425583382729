<template>
  <v-col
    v-if="employee"
    class="bar-shadow primary-background flex-column-with-tabs"
  >
    <div>
      <v-row class="pa-4">
        <v-col>
          <DetailsHeader
            :title="employee.name"
            :chip-text="employee.status"
            :chip-color="isActive ? 'green' : 'red'"
          >
            <template #subtitle>
              <div class="mr-4">{{ employee.position }}</div>
            </template>
          </DetailsHeader>
        </v-col>
        <v-col class="justify-end col-max-width-fit-content">
          <Button
            id="sync-employee-button"
            color="primary"
            class="ma-1"
            :action-names="[loadingTypes.syncEmployeeButton]"
            @clickAction="onEmployeeSync"
          >
            <span>{{ syncEmployeeText }}</span>
          </Button>
        </v-col>
      </v-row>

      <Details
        class="px-4 py-2"
        :details-columns="employeeDetails"
      />
    </div>

    <v-row>
      <v-tabs
        slider-color="primary"
        class="tabs-bar-shadow"
      >
        <v-tab>
          Settings
        </v-tab>

        <v-tab-item>
          <v-card
            class="employee-settings-card"
            max-width="290"
          >
            <v-col class="pa-4 inputs-column">
              <v-menu
                v-model="showEndOfProbationPicker"
                :close-on-content-click="false"
              >
                <template #activator="{ on }">
                  <v-text-field
                    class="secondary-background"
                    append-icon="event"
                    label="Trial Period End"
                    hide-details
                    outlined
                    dense
                    readonly
                    :disabled="!canEdit"
                    :value="employeeEndOfProbation"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="employee.end_of_probation"
                  color="primary"
                  no-title
                  :first-day-of-week="firstDayOfWeek"
                  @change="onProbationPeriodChanged"
                />
              </v-menu>
              <v-select
                v-model="employee.hour_per_week"
                class="secondary-background"
                label="Hours per Week"
                hide-details
                outlined
                dense
                :menu-props="{ offsetY: true }"
                :items="hoursPerWeekItems"
                :disabled="!canEdit"
                @change="onHoursPerWeekChanged"
              />
              <v-select
                v-model="employee.system_role_id"
                class="secondary-background"
                label="Role"
                item-text="title"
                item-value="id"
                hide-details
                outlined
                dense
                :menu-props="{ offsetY: true }"
                :items="systemRolesICanSet"
                :disabled="!canEdit"
                :value="employee.system_role_id"
                @change="onRoleChanged"
              />
            </v-col>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-col>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { mapCacheActions } from 'vuex-cache';

import { Button, DetailsHeader, Details } from '@/components/shared';
import hoursPerWeek from '@/constants/hoursPerWeek';
import { loadingTypes } from '@/constants/loadingTypes';
import { action, resource } from '@/constants/permissions';
import { systemRolesMapById } from '@/constants/roles';
import { DateHelper } from '@/helpers/date.helper';
import { NotificationHelper } from '@/helpers/notification.helper';
import apiClient from '@/services/api-client';

export default {
  components: {
    Button,
    Details,
    DetailsHeader,
  },
  data() {
    return {
      showEndOfProbationPicker: false,
      canEdit: false,
      syncEmployeeText: 'Sync',
      loadingTypes,
    };
  },
  computed: {
    ...mapState('employees/single', ['employee']),
    ...mapGetters('shared/offices', ['offices']),
    ...mapGetters('shared/roles', ['systemRolesICanSet']),
    ...mapGetters('user/settings', ['dateFormatSettings']),
    ...mapGetters('auth/account', ['user']),

    /** @returns {boolean} */
    isActive() {
      return this.employee.status && this.employee.status.toLocaleLowerCase() === 'active';
    },
    employeeEndOfProbation() {
      return this.employee.end_of_probation
        ? DateHelper.formatDateAccordingSettings(this.employee.end_of_probation, this.dateFormatSettings)
        : null;
    },
    firstDayOfWeek() {
      return DateHelper.getFirstDayOfWeekAccordingSettings(this.dateFormatSettings);
    },
    employeeDetails() {
      const employeeOffice = this.offices.find((office) => office.id === this.employee.office_id);

      return [
        {
          title: 'Employees',
          details: [
            {
              title: 'Office',
              value: employeeOffice == null ? null : employeeOffice.name,
            },
            {
              title: 'Joined',
              value: this.employee.joined
                ? DateHelper.formatDateAccordingSettings(this.employee.joined, this.dateFormatSettings)
                : null,
            },
            {
              title: 'Left',
              value: this.employee.left
                ? DateHelper.formatDateAccordingSettings(this.employee.left, this.dateFormatSettings)
                : null,
            },
            {
              title: 'Disabled',
              value: this.employee.is_disabled,
            },
          ],
        },
        {
          title: 'Contact information',
          details: [
            {
              title: 'Email',
              value: this.employee.email,
            },
            {
              title: 'Phone',
              value: this.employee.cell_phone !== 'NotSet' ? this.employee.cell_phone : null,
            },
            {
              title: 'Skype',
              value: this.employee.skype,
            },
            {
              title: 'Location',
              value: this.employee.location,
            },
          ],
        },
      ];
    },
    hoursPerWeekItems() {
      return hoursPerWeek;
    },
  },
  async created() {
    await this.setEmployee(this.$route.params.employee);
    const [{ data: { allowed: allowedToUpdateEmployeeDetails } }] = await Promise.all([
      apiClient.permissionsApi.allowedTo(action.UPDATE, resource.employeeDetails),
      this.getOffices(),
      this.getSystemRolesICanSet(),
      this.getEmployee({ employeeId: this.$route.params.id })]);

    this.canEdit = allowedToUpdateEmployeeDetails;
  },
  beforeDestroy() {
    this.setEmployee({});
  },
  methods: {
    ...mapActions('employees/single', [
      'getEmployee',
      'updateEmployee',
    ]),
    ...mapActions('employees/list', ['getEmployees']),
    ...mapActions('shared/roles', ['getSystemRolesICanSet']),
    ...mapActions('auth/account', ['updateUserData']),
    ...mapMutations('employees/single', ['setEmployee']),
    // Workaround to provide intellisense for `getOffices` method
    getOffices: mapCacheActions('shared/offices', ['getOffices']).getOffices,

    onProbationPeriodChanged(endOfProbation) {
      this.showEndOfProbationPicker = false;
      this.updateEmployee({
        employeeId: this.employee.id,
        patch: { end_of_probation: endOfProbation },
      });
    },
    onHoursPerWeekChanged(hourPerWeek) {
      this.updateEmployee({
        employeeId: this.employee.id,
        patch: { hour_per_week: hourPerWeek },
      });
    },
    onRoleChanged(systemRoleId) {
      this.updateEmployee({
        employeeId: this.employee.id,
        patch: { system_role_id: systemRoleId },
      });

      if (this.employee.id === this.user.id) {
        const newUserData = { ...this.user };

        // TODO: add types for system roles
        newUserData.role = systemRolesMapById[systemRoleId];

        this.updateUserData(newUserData);
      }
    },
    async onEmployeeSync() {
      const syncedEmployee = await apiClient.syncApi.syncEmployee(this.employee.id);

      if (syncedEmployee.data.sync_result === 'full_deleted') {
        NotificationHelper.showError(`Employee ${this.employee.name} has been deleted from DB`);
        this.getEmployees();
        await this.$router.push('/employees');
      } else {
        this.getEmployee({ employeeId: this.$route.params.id });
      }
    },
  },
};
</script>

<style lang="less">
@import "~variables";

  .email-text {
    align-self: center;
  }

  .employee-settings-card {
    width: 30%;
  }
</style>
